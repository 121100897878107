pre {
  font-family: "Montserrat", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 0.9rem;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.checkInContent {
  padding: 20px !important;

  @media screen and (max-width: 1000px) {
    padding: 0px !important;
  }

  .partnershipCheckInModal {
    ion-textarea {
      min-height: 70px !important;
      textarea {
        min-height: 70px !important ;
      }
    }
    .checkInPrompt {
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
    }
  }

  .expandButton {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
  }
}

.celebrain__container {
  display: flex;
  justify-content: center;
  .celebrain {
    width: 250px;
  }
}
