.partnershipCheckInContainer {
  overflow: auto;
  max-height: 250px;
  
    -webkit-box-shadow:rgba(0, 0, 0, 0.12) 0 4px 16px, inset 0px -22px 29px -30px rgba(135,135,135,1);
    -moz-box-shadow:rgba(0, 0, 0, 0.12) 0 4px 16px, inset 0px -22px 29px -30px rgba(135,135,135,1);
    box-shadow:rgba(0, 0, 0, 0.12) 0 4px 16px, inset 0px -22px 29px -30px rgba(135,135,135,1) !important;
  .bottomShadow {
    -webkit-box-shadow:rgba(0, 0, 0, 0.12) 0 4px 16px;
    -moz-box-shadow:rgba(0, 0, 0, 0.12) 0 4px 16px;
    box-shadow:rgba(0, 0, 0, 0.12) 0 4px 16px;
  }
    
  .partnershipCheckInContainerContent {
    margin-top: 20px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    
    .checkInDate {
      text-align: center;
      border-bottom: 1px solid var(--ion-color-tertiary);
      margin: 10px auto 20px auto;
    }
  }
  h5 {
    font-size: 14px;
    margin: 20px auto;
  }

  .pastCheckIns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .checkInSlides {
      height: 200px;
    }
    .checkInsArrows {
      display: flex;
      justify-content: space-evenly;
      align-content: center;
    }
  }
}

.noCheckInsContainer {
  min-width: 100px;
  min-height: 100px;
  margin: 10px;
}

.checkInFooter {
  ion-col {
    padding: 0;
  }  
}


.checkInFooterHiddenButton {
  display: flex;
  justify-content: center;
}

.checkInUsers {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;

  .checkInCard {
    min-width: 100px;

    .checkInCardContent {
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        margin-bottom: 5px;
      }
    }
  }
}
