.referralContainer {
  img {
    max-width: 250px;
  }
  
  .referralButtons {
    display: flex;
    justify-content: center;
  }
}
