.partnershipCheckInMessageModalContainer {

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 24px;
  text-align: left;

  .messageBox {
    max-height: 70%;
  }
}
