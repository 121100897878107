
.welcomeContainer {
  margin: 0;
  padding: 0;
  height: 100% !important;


  ion-content {
    height: 100% !important;
  }

  .ourBrainLogo {
    margin: 26px auto;
    max-width: 230px;
  }

  .selectedAvatar {
    display: flex;
    justify-content: center;

    ion-avatar {
      height: 110px;
      width: 110px;
    }
  }


  .cityList {
    overflow: auto;
    max-height: 350px;
    @media screen and (max-height: 600px) {
      max-height: 300px;

    }
    width: 100%;
    border-bottom: 2px solid var(--ion-color-secondary);
  }

  ion-avatar {
    height: 70px;
    width: 70px;
    margin: 10px 20px;
    display: inline-flex;

  }


  .profileContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 250px;
  }

  .avatarsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    
  }
  
  .usernameLabelContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    ion-label {
      padding-top: 6px;
    }
  }

  .isClicked {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 2px solid var(--ion-color-secondary);
    padding: 4px;
    border-radius: 100px;
  }


  .notClicked {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 100px;
  }

  .feelingsOverflow {
    max-height: 30vh;
    overflow: auto;
    border-bottom: 2px dashed var(--ion-color-medium-tint);
    margin-bottom: 10px;

    .feelingsContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 5px;
      padding: 10px;
      margin-top: 10px;

      border: 1px solid var(--ion-color-light);

      ion-checkbox {
        margin-right: 10px;
      }
    }
  }
}


.elementSpacing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;


}

.footer {
  display: flex !important;
  justify-content: center;

  margin-top: 50px;

  ion-button {
    margin: 10px 40px;
  }
}

#target {
  width: 100%;
  height: 100px;
  overflow-y: auto;
  overflow-x: auto;
  resize: both;
  position: relative;
  z-index: 2;
}

iframe {
  width: 100%;
  border: none;
}

.pdfViewer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  canvas {

    height: auto !important;
  }

  .pdfViewerNav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.disclaimerCheckbox {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    width: 80%;
  }
}
