.informationContainer {
  margin-bottom: 10px;
  //padding: 0 6px;

  .title {
    margin-bottom: 20px;
  }

  h4 {
    font-size: 1.5rem;
    color: var(--ion-color-primary-tint);
  }

  .paragraph {
    margin-bottom: 10px;

    ion-icon {
      padding-top: 20px;
    }
  }

  ion-grid {
    padding: 0;
  }

  ion-col {
    padding: 0;
  }

  ion-icon {
  }

}
