.tabBarContainer {
  border-top: 1px solid var(--ion-color-medium-tint);
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media screen and (min-width: 350px) {
    padding-bottom: 20px;
  }

  .tab {
    display: flex;
    flex-direction: column;
    text-align: center;

    ion-icon {
      cursor: pointer;
      margin: 10px 25px 0px;
    }
  }
}

a {
  text-decoration: none;
}
