#or {
  position: relative;
  width: 300px;
  height: 50px;
  margin: 0 auto;
  line-height: 50px;
  text-align: center;
}

#or::before,
#or::after {
  position: absolute;
  width: 130px;
  height: 1px;

  top: 24px;

  background-color: var(--ion-color-medium-tint);

  content: '';
}

#or::before {
  left: 0;
}

#or::after {
  right: 0;
}
