.header__container {
  border-bottom: 1px solid var(--ion-color-primary);
}

.ourBrainTItleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;

  h1 {
    margin: 0 auto 15px auto;
    
    @media screen and (max-width: 400px){
      font-size: 3rem;
    }
  }
}
