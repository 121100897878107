.privacyContainer {
  color: var(--primary-main);

  font-size: 1rem * 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;

  .privacyNavBar {
    display: flex;
    justify-content: space-between;
    align-items: center;


    a {
      display: flex;
      align-content: center;
      text-decoration: none;
    }
  }

  h3 {
    align-self: flex-start;
  }

  a {
    text-decoration: underline;
  }

  .privacyContent {
    max-width: var(--content-width);
    padding: 0 20px;
    background: var(--light-main);
    @media (max-width: 600px) {
      max-width: 90%;
    }


  }
}
