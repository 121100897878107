.dividerContent {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  padding: 10px 20px 10px 0;
  width: 100%;

  .dividerNotification {
    display: flex;
    justify-content: space-between;
    --color: var(--ion-color-light);
  }
}

.avatarContainer {
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;

  img {
    margin-top: 10px;
  }
}

.home__popup {
  .home__popup__buttons {
    display: flex;
    justify-content: center;
  }
}
