.subtitle {
  font-size: 1.2rem;
}

.partnershipCardContentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

}
