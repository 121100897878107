.helpPageContainer {
  color: var(--ion-color-dark);

  .helpPageContent {
    display: flex;
    justify-content: space-between;

    .helpPageIcons {
      ion-icon {
        margin: auto 7px;
      }

      a {
        margin: 0;
        color: inherit;
        text-decoration: none;
      }
    }
  }
}
