.notActiveUser {
  background: var(--ion-color-secondary-tint) !important;
}

.noCheckIn {
  display: flex;
  flex-direction: column;
}

.partnershipCheckInBoxContainer {
  height: 100px;
  padding: 5px;
  background: var(--ion-color-primary-tint);
  color: var(--ion-color-light);

  ion-card-content {
    height: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    ion-icon {
    }
  }
}
