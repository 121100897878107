.partnershipListContainer {

  .dashboardNew {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    
    
    .requestedPartnershipCard {
      margin: 30px auto;
    }
  }

  ion-card {
    max-width: 500px;
  }

  .noPartnerships {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  h1 {
    text-align: center;
  }
}
