.inputContainer {
  ion-icon {
    position: absolute;
    right: 0;
    top: 50%;
  }
}

.error {
  --border-color: var(--ion-color-danger, #f1453d);
}

