.create__post__container {
}

.create__submit__modal {
  .submit__form {
    padding: 15px;
    h3 {
      font-size: 1.5rem;
    }
    .post__buttons {
      margin-top: 20px;
    }
    .create__post__private__cb {
      .item-native {
        padding-inline-start: 0 !important;
        padding-inline-end: 0 !important;
      }
    }
  }
}

.type__card {
  cursor: pointer;
}
.thought__border {
  border: 5px solid var(--ion-color-primary);
}
.thought__bg {
  --background: var(--ion-color-primary);
}
.story__border {
  border: 5px solid var(--ion-color-secondary);
}
.story__bg {
  --background: var(--ion-color-secondary);
}
.tip__border {
  border: 5px solid var(--ion-color-tertiary);
}
.tip__bg {
  --background: var(--ion-color-tertiary);
  --color: #000;
}
.announcement__border {
  border: 5px solid var(--ion-color-medium);
}
.announcement__bg {
  --background: var(--ion-color-medium);
  --color: #000;
}
