#line {
  position: relative;
  width: 300px;
  height: 50px;
  margin: 0 auto;
  line-height: 50px;
  text-align: center;
}

#line::before,
#line::after {
  position: absolute;
  width: 200px;
  height: 1px;

  top: 24px;

  background-color: var(--ion-color-secondary);

  content: '';
}

#line::before {
  left: 0;
}

#line::after {
  right: 0;
}
