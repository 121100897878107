.feedPostItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 3px 7px;
  margin: 5px auto;
}

.thought__border {
  border-left: 4px solid var(--ion-color-primary);
}
.thought__bg {
  --background: var(--ion-color-primary);
}
.story__border {
  border-left: 4px solid var(--ion-color-secondary);
}
.story__bg {
  --background: var(--ion-color-secondary);
}
.tip__border {
  border-left: 4px solid var(--ion-color-tertiary);
}
.tip__bg {
  --background: var(--ion-color-tertiary);
  --color: #000;
}
.announcement__border {
  border-left: 4px solid var(--ion-color-light);
}
.announcement__bg {
  --background: var(--ion-color-light);
  --color: #000;
}
