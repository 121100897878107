@import url("https://fonts.googleapis.com/css?family=Montserrat:600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Palanquin+Dark:500&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

$secondary-dark: #f0a253;

:root {
  --ion-font-family: Montserrat;
}

h1 {
  font-size: 4rem;
  font-family: "Palanquin Dark", Roboto, sans-serif;
}

h3 {
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
}
