h1,
h3 {
  color: var(--ion-color-primary);
}

.login__page {
  .login__form__container {
    .login__form__title {
      margin: 2rem auto;
    }

    .login__form_fields {
      margin: 2rem auto;
    }
  }
}
