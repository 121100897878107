.messageBoxContainer {
  display: flex;
  width: 100%;

  .messageBox {
    flex-direction: column;
    margin: 6px 20px;
    padding: 8px 10px;

    min-width: 180px;
    display: flex;
    justify-content: space-between;

    .messageInfo {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }

}

p {
  margin: 0;
  padding: 0;
}

.leftJustify {
  justify-content: flex-start;

  .messageBox {
    border-radius: 8px 8px 8px 0px;
    border: 2px solid var(--ion-color-primary);
    color: var(--ion-color-primary);
  }
}

.rightJustify {
  justify-content: flex-end;

  .messageBox {
    border-radius: 8px 8px 0 8px;
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
  }
}
