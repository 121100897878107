.sign__up__page {
  .sign__up__form__title {
    margin: 2rem auto;
  }
  .terms {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    margin: 15px auto;
    padding: 0.8rem 0.4rem;
    border-radius: 0.2rem;
    background-color: rgb(255, 255, 255);
    width: 100%;
    transition: all 0.3s;
    .terms__text {
      margin-right: 10px;
    }
  }
}

@keyframes password__color__change__low {
  from {
    border-top: 3px solid var(--ion-color-light);
  }
  to {
    border-top: 3px solid var(--ion-color-danger);
  }
}
@keyframes password__color__change__med {
  from {
    border-top: 3px solid var(--ion-color-light);
  }
  to {
    border-top: 3px solid var(--ion-color-warning);
  }
}
@keyframes password__color__change__high {
  from {
    border-top: 3px solid var(--ion-color-light);
  }
  to {
    border-top: 3px solid var(--ion-color-success);
  }
}

.password__strength {
  display: flex;
  flex-direction: column;
  .password__strength__box__container {
    display: flex;
    justify-content: space-evenly;
    flex-grow: 3;
    margin-bottom: 20px;

    .password__strength__box {
      min-width: 33%;
      height: 2px;
      border-top: 3px solid var(--ion-color-light);
    }

    .password__strength__low {
      border-top: 3px solid var(--ion-color-danger);
      animation-name: password__color__change__low;
      animation-duration: 2s;
    }

    .password__strength__med {
      border-top: 3px solid var(--ion-color-warning);
      animation-name: password__color__change__med;
      animation-duration: 2s;
    }

    .password__strength__high {
      border-top: 3px solid var(--ion-color-success);
      animation-name: password__color__change__high;
      animation-duration: 2s;
    }
  }
}
