
ion-alert {
  @media screen and (min-width: 350px) {
    --min-width: 330px !important;
  }
  @media screen and (max-width: 325px) {
    --min-width: 310px !important;
  }
}

.slides {
  overflow-y: scroll;

.slideContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
  
  .slideQuestion {
    margin: 20px auto;
    width: 100%;
  }

    .label {
      margin-bottom: 20px;
    }
    
    textarea {
      border-bottom: 1px solid var(--ion-color-light);
    }
  
  .submitButtons {
  }
}
}
.footer {
  display: flex;
  justify-content: center;
}

.disclaimerContainer {
  text-align: left;
  
  .pdfViewer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    canvas {

      height: auto !important;
    }

    .pdfViewerNav {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  }

  .disclaimerCheckbox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      width: 80%;
    }
  }
}
