
ion-button {
  --border-radius: 6px !important;
}

.gridFlex {
  display: flex;
  flex-direction: column;
}

ion-alert {

  button {
    margin: 10px auto!important;
    overflow-wrap: break-word;
    min-height: 60px !important;

  }

  button div {

    white-space: normal !important;
  }
}


