.contentContainer {
  display: flex;
  flex-wrap: wrap;
.partnershipCardContainer {
  max-width: 200px;
  min-height: 150px;
  .partnershipCardContent {
    display: flex;
    justify-content: space-between;

  }
}
}
