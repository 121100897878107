$primary-main: #86c8d3;
$primary-light: #d4f1f7;
$primary-dark: #4e95a3;
$secondary-main: #f7c28b;
$secondary-light: #fce9d4;

:root {
  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;

  --ion-color-primary: #2a6176;
  --ion-color-primary-rgb: 42, 97, 118;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #255568;
  --ion-color-primary-tint: #3f7184;

  --ion-color-secondary: #ca8fae;
  --ion-color-secondary-rgb: 202, 143, 174;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #b27e99;
  --ion-color-secondary-tint: #d8b3c3;

  --ion-color-tertiary: #eae7d9;
  --ion-color-tertiary-rgb: 234, 231, 217;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #cecbbf;
  --ion-color-tertiary-tint: #ece9dd;

  --ion-color-success: #ABE4C9;
  --ion-color-success-rgb: 171,228,201;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #96c9b1;
  --ion-color-success-tint: #b3e7ce;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #e0e1e5;
  --ion-color-light-rgb: 224, 225, 229;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #c5c6ca;
  --ion-color-light-tint: #e3e4e8;

  --ion-color-facebook: #5591cd;
  --ion-color-facebook-rgb: 85, 145, 205;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 0, 0, 0;
  --ion-color-facebook-shade: #4b80b4;
  --ion-color-facebook-tint: #669cd2;

  --ion-color-gold: #ffd700;
  --ion-color-gold-rgb: 255, 215, 0;
  --ion-color-gold-contrast: #000000;
  --ion-color-gold-contrast-rgb: 0, 0, 0;
  --ion-color-gold-shade: #e0bd00;
  --ion-color-gold-tint: #ffdb1a;
}

.ionColorFacebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-gold {
  --ion-color-base: var(--ion-color-gold) !important;
  --ion-color-base-rgb: var(--ion-color-gold-rgb) !important;
  --ion-color-contrast: var(--ion-color-gold-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-gold-shade) !important;
  --ion-color-tint: var(--ion-color-gold-tint) !important;
}
