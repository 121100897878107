.submitCheckInModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 30px 0;

  .checkInList {
    max-height: 80%;

    .checkInItem {
      display: flex;
      justify-content: space-between;
    }
  }
}
