.partnershipContainer {

  ion-footer {
    display: flex;
    align-items: center;
    border-top: 2px solid var(--ion-color-primary);
  }

  h1 {
    text-align: center;
  }

  .textAreaCenter {
    align-items: center;
  }


  .messages {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: scroll;
    min-height: 100%;

    &.noMessages {
      justify-content: center;
    }
  }
}

::-webkit-input-placeholder {
  text-align: left;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: left;
}

::-moz-placeholder { /* Firefox 19+ */
  text-align: left;
}

:-ms-input-placeholder {
  text-align: left;
}

ion-icon {
  font-size: 26px;
}
