.settingsContainer {
  .settingsText {
    text-align: center;
  }

  .settingsHeader {
    display: flex;
    margin: 44px 10px;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    .settings__header__avatar {
      height: 50px;
      width: 50px;
      margin-right: 10px;
    }
  }

  .settingsSectionContainer {
    margin: 16px auto;
  }

  ion-avatar {
    cursor: pointer;
    height: 100px;
    width: 100px;
    margin: 0 0;
  }

  .changePhotoOverlay {
    position: relative;
  }

  .img:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 1s;
    -webkit-transition: all 1s;
  }

  .image:hover:after {
    opacity: 1;
  }
}

.referencesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  * {
    margin: 20px 10px;
  }
}

.footerInformation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .infoSecure {
    margin-top: 5px;
    display: flex;
    align-items: center;

    * {
      padding: 2px;
    }
  }
}
