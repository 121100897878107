.form__label {
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  margin-left: 2rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
  transform: translateY(0rem);
}

.form__input {
  color: #333;
  font-size: 1.1rem;
  margin: 15px auto;
  padding: 0.8rem 2rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: 2px solid #f5e3ea;
  width: 100%;
  display: block;
  transition: all 0.3s;
}

.form__input__filled {
  border: 2px solid var(--ion-color-secondary-tint);
}
.form__input__error {
  border: 2px solid var(--ion-color-danger);
}
