.alert-checkbox-label.sc-ion-alert-md {
  white-space: pre-line !important;
}

alert-checkbox-label.sc-ion-alert-ios {
  white-space: pre-line !important;
}

ion-label {
  white-space: normal !important;
}
ion-alert {
  &.selectOption{
    .alert-radio-label {
      white-space: unset;
    }
  }
}
