.feed__container {
  .feed__loading__dots {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 20px;
  }
}

.post__container {
  cursor: auto;
  .header__container {
    display: flex;
    align-items: center;
  }
  .post__footer {
    padding: 5px 0;
    text-align: right;
    font-size: 0.8rem;
    .post__footer__time {
      padding: 0 20px;
    }
    .post__footer__save {
      margin-left: 2px;
    }
  }
}

.type__box {
  --color: #fff;
  text-align: center;
  padding: 10px 0;

  .type__box__text {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

.thought__border {
  border: 5px solid var(--ion-color-primary);
}
.thought__bg {
  --background: var(--ion-color-primary);
}
.story__border {
  border: 5px solid var(--ion-color-secondary);
}
.story__bg {
  --background: var(--ion-color-secondary);
}
.tip__border {
  border: 5px solid var(--ion-color-tertiary);
}
.tip__bg {
  --background: var(--ion-color-tertiary);
  --color: #000;
}
.announcement__border {
  border: 5px solid var(--ion-color-medium);
}
.announcement__bg {
  --background: var(--ion-color-medium);
  --color: #000;
}

.fab {
  display: flex;
  justify-content: center;

  ion-button {
    width: 100%;
  }

  .fab__share {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .fab__share__button {
      margin-right: 10px;
    }
  }
}

.load__more__btn {
  margin-bottom: 60px;
}

.post__avatar {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.filter__nested {
  padding-left: 40px;
}
